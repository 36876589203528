import React, { useState } from 'react';
import background from './background.png';
import qrCode from './contact-us.png';
import browser from './browser.png';
import browserMobile from './browser-mobile.png';
import './App.css';

function App() {
    const [showQrCode, setShowQrCode] = useState(false);
    const handleClick = () => {
        setShowQrCode(value => !value);
    }
  return (
      <div className="App" style={{backgroundImage: `url(${background})`}}>
          <div className="App-header">
              <div className="title">
                  <div className="name">跃迁浏览器</div>
                  <div className="label">Alpha</div>
              </div>
              <div className="name">我们专注于效率与美</div>
              <div className="download">
                  <a href="https://resources.jumpingbrowser.com/Jump-0.2.0-x64.dmg" className="resource">
                      <div className="button">
                          <i className="iconfont icon-apple"/>
                          <div className="text">下载 macOS 版（ Intel 芯片）</div>
                      </div>
                  </a>
                  <a href="https://resources.jumpingbrowser.com/Jump-0.2.0-arm64.dmg" className="resource">
                      <div className="button">
                          <i className="iconfont icon-apple"/>
                          <div className="text">下载 macOS 版（ Apple 芯片）</div>
                      </div>
                  </a>
                  <a href="https://resources.jumpingbrowser.com/Jump%20Browser%20Setup%200.2.0.exe" className="resource">
                      <div className="button">
                          <i className="iconfont icon-windows"/>
                          <div className="text">下载 Windows 版</div>
                      </div>
                  </a>
              </div>
              <div className="guide-pc">请在电脑端打开本页面下载软件</div>
              <div className="contact">
                  <div className="contact-us" onClick={handleClick}>联系我们</div>
                  <img src={qrCode} alt="" className="qrcode" style={{ opacity: showQrCode ? 1 : 0 }} />
              </div>
          </div>
          <img src={browser} alt="" className="browser"/>
          <img src={browserMobile} alt="" className="browser-mobile"/>
          <a href="https://beian.miit.gov.cn/" target="_blank" className="icp">浙ICP备2024123737号-1</a>
      </div>
  );
}

export default App;
